.class-page .main {
    padding: 32px 16px 80px;
    max-width: 1280px;
    margin: auto;
}

.class-page .content-header {
    margin-bottom: 8px !important;
}

.class-page .description {
    margin-top: 32px;
}

@media screen and (max-width: 992px) {
    .class-page .description {
        margin-top: 0;
    }

    .class-page .main > *:first-of-type {
        flex-direction: row-reverse !important;
    }
}
