.lab-card {
    border-top: 1px solid #eaeded;
    border-radius: 0;
    margin: 1rem auto 2rem;
    background-color: #fff;
    box-sizing: border-box;
    max-width: 1100px;
    box-shadow: 0 1px 1px 0 rgba(0, 28, 36, 0.3), 1px 1px 1px 0 rgba(0, 28, 36, 0.15),
        -1px 1px 1px 0 rgba(0, 28, 36, 0.15);
    word-wrap: break-word;
}

.lab-card p {
    padding: 0 2rem;
    margin-bottom: 2rem;
    font-size: 1.25rem;
}

.lab-card__actions {
    position: relative;
    padding: 2rem;
}

.lab-card__actions:before {
    content: '';
    position: absolute;
    top: 0;
    left: 1.5rem;
    right: 1.5rem;
    height: 1px;
    background-color: #aab7b8;
}

.lab-card__actions:after {
    content: '';
    display: block;
    float: none;
}

.lab-card__header {
    padding: 2rem;
}

.lab-card__header h3 {
    display: inline-block;
    margin-right: 1.5rem;
}

.lab-card__header span {
    text-transform: uppercase;
    color: #545b64;
}

.lab-card__actions .lab-card__toggle {
    float: right;
    min-width: 10rem;
}
