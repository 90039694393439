section[data-testid='class-list-section'] {
    margin: 32px auto 0;
    max-width: 1100px;
}

section[data-testid='class-list-section'] > div {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

section[data-testid='class-list-section'] h2 {
    margin-bottom: 8px !important;
}

section[data-testid='class-list-section'] [data-testid='early-warning'] {
    font-style: italic;
}
